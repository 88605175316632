<template>
	<van-overlay :show="isLoad" class-name="loading">
		<van-loading color="#1989fa" />
	</van-overlay>
</template>
<script>
export default {
	name: 'loading',
    data () {
        return {
        }
    },
    props: {
        isLoad: {
            type: Boolean,
            default: false,
        },
    }
}
</script>
<style scoped>
.loading{
	background: none;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>