import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch(err => err)
}

const router = new Router({
	mode: 'history',
	scrollBehavior: () => ({
		y: 0
	}),
	routes: [{
		path: "/",
		component: (resolve) => require(['@/view/index/index.vue'], resolve),
	},{
		path: "/login/login.html",
		component: (resolve) => require(['@/view/login/login.vue'], resolve),
	}]
})

const whiteList = ['/login/login.html']
router.beforeEach((to, from, next) => {
	let token = localStorage.token
	let path = to.path.toLowerCase()
	if (token && whiteList.indexOf(path) !== -1) {
		next({
			path: '/'
		})
	} else if (!token && whiteList.indexOf(path) === -1) {
		next({
			path: "/login/login.html",
			query: {
				redirect: to.fullPath
			}
		})
	} else{
		next()
	}
})

export default router
