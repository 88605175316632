<template>
	<div id="app" v-cloak>
		<router-view></router-view>
	</div>
</template>
<script>
export default {
	data() {
		return {
		}
	}
}
</script>
<style>
	*,*:after,*::before{
		box-sizing:border-box;
		-ms-box-sizing:border-box;
		-moz-box-sizing:border-box;
		-webkit-box-sizing:border-box;
	}
	ul {
		display: block;
		list-style-type: disc;
		margin-block-start: 0px;
		margin-block-end: 0px;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 0px;
	}
	*{
		text-decoration:none;
		list-style:none;
		margin:0;
		padding:0;
		border:none;
		line-height: 1;
		font-weight:normal;
		font-size:0.7rem;
	}
	[v-cloak]{
		display: none;
	}
	#app {
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
		color: #2c3e50;
	}

	.container{
		display: block;
		width: 100%;
		margin: 0 auto;
		font-size: 0.7rem;
	}
	.container .main{
		display: block;
		width: 95%;
		margin: 0 auto;
	}
	input{
		border: 1px solid #cccccc;
		padding: .5rem 1rem;
		border-radius: 0.25rem;
		font-size: 0.7rem;
	}
	.title{
		font-weight: bold;
	}
	.btndiv{
		width: 100%;
		margin: 0 auto;
		text-align: center;
		padding-bottom: 1.25rem;
	}
	.btn{
		background-image: linear-gradient(to right, #8551d9,  #7765eb);
		color:#ffffff;
		width: 80%;
		height: 2rem;
		line-height: 2rem;
		border-radius: 2rem;
		margin-top: 1.5rem;
	}
	.back{
		display: flex;
		position: absolute;
		top:0.5rem;
		left:0.5rem;
	}
	.back img{
		width: 1.5rem;
	}
	.header{
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		text-align: center;
		height: 1.8rem;
		line-height: 1.8rem;
		color: #FFFFFF;
		background-image: linear-gradient(to right, #8551d9,  #7765eb);
		position: fixed;
		z-index: 1;
		top:0;
	}
	.header img{
		position: absolute;
		width: 1rem;
		height: 1rem;
		left:0.5rem
	}
input,select{
	-webkit-appearance: none;
}
</style>