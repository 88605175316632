import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import axios from 'axios'
import Vant from 'vant';
import 'vant/lib/index.css';
import Loading from './components/loading'

Vue.use(Vant);
Vue.use(Loading);
Vue.config.productionTip = false
axios.defaults.baseURL = 'https://oapi.dianmic.cn'
// axios.defaults.baseURL = 'http://localhost:7777'
Vue.prototype.app_url = 'https://o.dianmic.cn'
Vue.prototype.imgoss = 'http://oss.eastlove.jm.xm0592.com'
Vue.prototype.axios = axios
axios.interceptors.request.use(config => {
	let token = localStorage.token;
	if (token)
		config.headers.token = token
	return config
})
axios.interceptors.response.use(response => {
	if(response.data.code == 401){
		localStorage.clear()
		router.replace({
			path: "/login/login.html",
			query: {
				redirect: router.app._route.fullPath
			}
		});
		return Promise.reject('请先登录');
	}else if(typeof response.data.code == 'undefined'){
		let message = ''
		if(typeof response.data.message == 'undefined')
			message = '网络异常'
		else
			message = response.data.message
		Vue.prototype.$notify({
			type: 'warning',
			message: message
		});
		return Promise.reject(message);
	}else{
		return response.data
	}
}, () => {
	localStorage.clear()
	router.replace({
		path: "/login/login.html",
		query: {
			redirect: router.app._route.fullPath
		}
	});
	return Promise.reject('请求异常');
})

var rem = 0;
(function(doc, win) {
	var remClient = 0
	if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))
		remClient = 18.75;
	else
		remClient = 96;
	var docEl = doc.documentElement,
		resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
		recalc = function() {
			var clientWidth = docEl.clientWidth;
			rem = clientWidth / remClient;
			docEl.style.fontSize = rem + 'px';
			function adapt() {
				var d = window.document.createElement('div');
				d.style.width = '1rem';
				d.style.display = "none";
				var head = window.document.getElementsByTagName('head')[0];
				head.appendChild(d);
				var defaultFontSize = parseFloat(window.getComputedStyle(d, null).getPropertyValue('width'));
				return defaultFontSize
			}
			rem = rem * rem / adapt();
			docEl.style.fontSize = rem + 'px'
		};
	if (!doc.addEventListener) return;
	win.addEventListener(resizeEvt, recalc, false);
	doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);

new Vue({
	render: h => h(App),
	router
}).$mount('#app')
